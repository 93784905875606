// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}
.feedback-container .feedback-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
}
.feedback-container .feedback-close-icon {
  color: #929DB1;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}
.feedback-container .feedback-body {
  display: flex;
  flex-direction: column;
}
.feedback-container .feedback-body .feedback-counter {
  text-align: left;
  font-size: small;
  margin: 0 0 20px 0;
}
.feedback-container .feedback-body .feedback-rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  gap: 30px;
  color: #E3000E;
}
.feedback-container .feedback-body .feedback-rating .feedback-rating-star {
  transform: scale(1.5);
}
.feedback-container .feedback-footer {
  margin-top: 20px;
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
