import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiExtendedService } from '@app/api/root/api-extended.service';
import { PriceVariant } from '@app/models/price/price-variant';
import { PricingResponse } from '@app/models/price/pricing-response';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends ApiExtendedService {
  constructor() {
    super();
  }

  public getPriceForVariant(payload: PriceVariant): Promise<HttpErrorResponse | HttpResponse<PricingResponse>> {
    return this.postResource<PricingResponse, PriceVariant>(`pricing/variant`, payload);
  }
}
