import { NgModule } from '@angular/core';
import { PipesModule } from '@app/modules/pipes/pipes-module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { SharedImportsModule } from '@app/shared/shared-imports.module';
import { ViewHeightDirective } from '@app/directives/ViewHeight.directive';
import { AddressInputComponent } from '@app/shared/components/address-input/address-input.component';
import { AndrowebInputControlComponent } from '@app/shared/components/androweb-input-control/androweb-input-control.component';
import { BasketModule } from '@app/shared/components/basket/basket.module';
import { DealsComponent } from '@app/shared/components/deals/deals.component';
import { ProductComponent } from '@app/shared/components/product/product.component';
import { FeedbackComponent } from '@app/shared/components/feedback/feedback.component';

@NgModule({
  declarations: [
    AddressInputComponent,
    AndrowebInputControlComponent
  ],
  exports: [
    AddressInputComponent,
    AndrowebInputControlComponent,
    BasketModule,
    DealsComponent,
    PipesModule,
    ProductComponent,
    SharedMaterialModule,
    SharedImportsModule,
    ViewHeightDirective,
    FeedbackComponent
  ],
  imports: [
    BasketModule,
    PipesModule,
    SharedMaterialModule,
    SharedImportsModule,
    ViewHeightDirective,
    DealsComponent,
    ProductComponent,
    FeedbackComponent
  ]
})
export class SharedComponentsModule { }
