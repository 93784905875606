import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { IDictionary } from '@app/models/IDictionary';
import { ApiExtendedService } from '@app/api/root/api-extended.service';
import { SiteService } from '@app/api/site.service';
import { Menu } from '@app/models/menu/menu';
import { ProductBase } from '@app/models/menu/product-base';
import { ProductVariant } from '@app/models/menu/product-variant';
import { Product } from '@app/models/menu/product';
import { ProductOptions } from '@app/models/menu/product-options';
import { Variant } from '@app/models/menu/variant';
import { Deal } from '@app/models/menu/deal';
import { CachedMenu } from '@app/models/menu/cached-menu';

@Injectable()
export class MenuService extends ApiExtendedService {
  public menu$: Observable<Menu>;

  private _menu = new BehaviorSubject<Menu>(null);
  private _productVariantsById: IDictionary<ProductVariant> = {};
  private _productOptionsById: IDictionary<ProductBase> = {};
  private _menuBySiteId: IDictionary<CachedMenu> = {};

  constructor(
    private siteService: SiteService
  ) {
    super();
    this.menu$ = this._menu.asObservable();

    this.menu$.subscribe((menu: Menu) => {
      if (!menu) {
        return;
      }

      menu.Products.forEach((product: Product) => {
        product.Variants.forEach((variant: Variant) => {
          this._productVariantsById[variant.Id] = {
            Product: product,
            Variant: variant
          };
        });
      });

      menu.ProductOptions
          .flatMap((productOptions: ProductOptions) => productOptions.Options)
          .forEach((option: ProductBase) => {
            if (this._productOptionsById[option.Id]) {
              return;
            }

            this._productOptionsById[option.Id] = option;
          });
    });

    this.siteService.currentSite$.subscribe(async (site) => {
      if (!site) {
        return;
      }

      this._menu.next(await this.getMenuFromApi(site.Id));
    });
  }

  public get menuValue(): Menu {
    return this._menu.value;
  }

  public get productVariantsById(): IDictionary<ProductVariant> {
    return this._productVariantsById;
  }

  public get productOptionsById(): IDictionary<ProductBase> {
    return this._productOptionsById;
  }

  public async getMenu(siteId: string): Promise<CachedMenu> {
    if (!this._menuBySiteId[siteId]) {
      const menu = await this.getMenuFromApi(siteId);

      if (!menu) {
        return null;
      }

      this._menuBySiteId[siteId] = {
        menu,
        variantsById: {},
        optionsById: {}
      };

      menu.Products.forEach((product: Product) => {
        product.Variants.forEach((variant: Variant) => {
          this._menuBySiteId[siteId].variantsById[variant.Id] = {
            Product: product,
            Variant: variant
          };
        });
      });

      menu.ProductOptions
          .flatMap((productOptions: ProductOptions) => productOptions.Options)
          .forEach((option: ProductBase) => {
            if (this._menuBySiteId[siteId].optionsById[option.Id]) {
              return;
            }

            this._menuBySiteId[siteId].optionsById[option.Id] = option;
          });
    }

    return this._menuBySiteId[siteId];
  }

  private async getMenuFromApi(siteId: string): Promise<Menu> {
    const response: Menu | HttpErrorResponse = await this.getResourceAsync<Menu>(`sites/${siteId}/v2/menu`);

    if (response instanceof HttpErrorResponse) {
      return null;
    }

    return this.transformMenu(response);
  }

  /**
   * makes all product/deal images small and removes magic characters from deal descriptions.
   * @param menu
   */
  private transformMenu(menu: Menu): Menu {
    for (const deal of menu.Deals) {
      this.checkDealsForMagicDescription(deal);
    }

    return this.checkProductsForProviderDeals(menu);
  }

  /**
   * TEMP SOLUTION
   * removes well defined text for Order Providers on the customerDescription.
   * Additionally locks all '!A' to filter out products not for Androweb.
   * Example text || !A, XJE, XUE, XD ||
   * @param menu
   */
  private checkDealsForMagicDescription(deal: Deal): void {
    if (!deal.Description) {
      return;
    }

    const regNotAndroWeb: RegExp = /\|\|.*!A.*\|\|/i;
    if (regNotAndroWeb.test(deal.Description)) {
      deal.RequiresUnlock = true;
      return;
    }

    const reg: RegExp = /\|\|((?!\|\|).+)\|\|/g;
    deal.Description = deal.Description.replace(reg, '');
  }

  /**
   * TEMP SOLUTION
   * removes well defined text for Order Providers on the customerDescription.
   * Additionally locks all '!A' to filter out products not for Androweb.
   * Example text || !A, XJE, XUE, XD ||
   * @param menu
   */
  private checkProductsForProviderDeals(menu: Menu): Menu {
    const excludeFromAndroweb: RegExp = /\|\|.*!A.*\|\|/i;

    const products = menu.Products.filter((x: Product) => !x.Description || !excludeFromAndroweb.test(x.Description));

    const reg: RegExp = /\|\|((?!\|\|).+)\|\|/g;

    products.filter((x: Product) => !!x.Description)
        .forEach((x: Product) => {
          x.Description = x.Description.replace(reg, '');
        });

    menu.Products = products;

    return menu;
  }
}
